// eslint-disable-next-line import/prefer-default-export
export const SETTING_CUSTOMER_DEFAULTS = {
  action: 'view',
  resource: 'Defaults',
}
export const SETTING_CUSTOMER_TEMPLATES = {
  action: 'view',
  resource: 'Templates',
}

// export const SETTING_CUSTOMER_PAYMENT_OPTIONS = {
//   action: 'view',
//   resource: 'Payment Options',
// }
