import {
  SETTING_CUSTOMER_DEFAULTS,
  SETTING_CUSTOMER_TEMPLATES,
  // SETTING_CUSTOMER_PAYMENT_OPTIONS,
} from '../meta/settings-sub-menu/customer'

// eslint-disable-next-line import/prefer-default-export
export default function customerMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-customer-defaults',
      layout: 'Defaults',
      ...SETTING_CUSTOMER_DEFAULTS,
    },
    {
      name: 'settings-customer-templates',
      layout: 'Templates',
      ...SETTING_CUSTOMER_TEMPLATES,
    }
    // {
    //   name: 'settings-customer-payment-options',
    //   layout: 'Payment Options',
    //   ...SETTING_CUSTOMER_PAYMENT_OPTIONS,
    // },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })

  return {
    menuItems,
  }
}
